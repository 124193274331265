<template>
    <div class="hairyCrab">
        <div class="form" v-if="!showSuccessPage">
            <div class="label">卡号：</div>
            <div class="formItem">
                <div class="formItemInput">
                    <input placeholder="请输入" :type="inputType" :value="cardNumber" @input="changeCardNumber"
                        @blur="blur" />
                </div>
                <div class="warning" v-show="!!cardNumberCheckInfo">{{ cardNumberCheckInfo }}</div>
            </div>
            <div class="label">密码：</div>
            <div class="formItem">
                <div class="formItemInput">
                    <input placeholder="请输入" :type="inputType" :value="cardPassword" @input="changeCardPassword" />
                </div>
            </div>
            <div class="label">收货人姓名：</div>
            <div class="formItem">
                <div class="formItemInput">
                    <input placeholder="请输入" :value="name" @input="changeName" />
                </div>
            </div>
            <div class="label">收货人手机号：</div>
            <div class="formItem">
                <div class="formItemInput">
                    <input placeholder="请输入" type="tel" :value="phone" maxlength="12" @input="changePhone" />
                </div>
            </div>
            <div class="label">预约发货日期：</div>
            <div class="formItem">
                <div class="formItemInput" @click="showCalendar = true">
                    <input placeholder="请选择时间" :value="date" class="special" />
                </div>
                <van-calendar :value="date" :min-date="minDate" :max-date="maxDate" v-model:show="showCalendar"
                    :show-confirm="false" @confirm="changeDate" />
            </div>
            <div class="label">收货地址：</div>
            <div class="formItem">
                <div class="formItemInput" @click="showVdistpicker = true">
                    <input placeholder="请选择省市区" :value="address1" class="special" />
                </div>
                <div class="vDistpicker" v-show="showVdistpicker">
                    <div class="mask" @click="showVdistpicker = false" />
                    <VDistpicker type="mobile" @selected="changeAddress1" />
                </div>
                <div class="formItemInput">
                    <input placeholder="请输入详细地址" :value="address2" @input="changeAddress2" />
                </div>
            </div>
            <div class="submit">
                <button @click="submit" class="submitBtn"
                    :disabled="!cardNumber || !cardPassword || !name || !phone || !address1 || !address2">确定兑换</button>
                <van-dialog class="pages-HairyCrab-index-vanDialog" v-model:show="showDialog" show-cancel-button
                    cancel-button-text="返回修改" cancel-button-color="#969696" confirm-button-color="#29C985"
                    @confirm="confirmOk" @cancel="confirmCancel">
                    <div class="header">温馨提示</div>
                    <div class="content">
                        <div class="tip">请确认收货信息是否正确？</div>
                        <div class="info"><span class="label">姓名：</span><span class="text">{{ name }}</span></div>
                        <div class="info"><span class="label">手机号：</span><span class="text">{{ phone }}</span></div>
                        <div class="info"><span class="label">预约发货日期：</span><span class="text">{{ date }}</span></div>
                        <div class="info"><span class="label">地址：</span><span class="text">{{ address1 }}{{ address2
                                }}</span>
                        </div>
                    </div>
                </van-dialog>
            </div>
            <div class="tips">兑换成功后，我们将在预约发货日期后的48小时内尽快为您发货，发货后预计2-3天收到商品。如遇商品问题，请按照礼品卡上注明的电话联系客服。</div>
        </div>
        <div class="successPage" v-else>
            <div class="icon" />
            <div class="text">兑换成功<br />我们将尽快为您发货</div>
            <div class="btn" @click="close">返回首页</div>
        </div>
    </div>
</template>
<script>
import VDistpicker from "v-distpicker";
import * as vant from "vant";
import { agent } from "../../commons";
import moment from "moment";
export default {
    components: { VDistpicker },
    data() {
        return {
            showDialog: false,
            showSuccessPage: false,
            showVdistpicker: false,
            cardNumberCheckInfo: null,
            cardNumber: "",
            cardPassword: "",
            name: "",
            phone: "",
            date: null,
            minDate: moment().add(1, "day").toDate(),
            maxDate: moment("2024-12-25").toDate(),
            address1: "",
            address2: "",
            inputType: "password",
            showCalendar: false,
        };
    },
    mounted() {
        document.title = "兑换好礼";
    },
    methods: {
        changeCardNumber(e) {
            this.inputType = "text";
            this.cardNumber = e.target.value;
        },
        async blur() {
            if (this.cardNumber) {
                try {
                    const res = await agent.get(`https://portal.sqbj.com/gc/h5/card/checkByCardNo?cardNo=${this.cardNumber}`);
                    if (res.code === 0) {
                        this.cardNumberCheckInfo = null;
                    } else {
                        this.cardNumberCheckInfo = res.msg;
                    }
                } catch (e) {
                    vant.Toast(e.message || "操作失败");
                }
            } else {
                this.cardNumberCheckInfo = null;
            }
        },
        changeCardPassword(e) {
            this.cardPassword = e.target.value;
        },
        changeName(e) {
            this.name = e.target.value;
        },
        changePhone(e) {
            this.phone = e.target.value;
        },
        changeDate(e) {
            this.date = moment(e).format("YYYY-MM-DD");
            this.showCalendar = false;
        },
        changeAddress1(data) {
            this.address1 = `${data.province.value}${data.city.value}${data.area.value}`;
            this.showVdistpicker = false;
        },
        changeAddress2(e) {
            this.address2 = e.target.value;
        },
        async submit() {
            if (!this.cardNumber) {
                return vant.Toast("卡号不能为空");
            }
            if (!this.cardPassword) {
                return vant.Toast("密码不能为空");
            }
            if (!this.name) {
                return vant.Toast("收货人姓名不能为空");
            }
            if (!this.phone) {
                return vant.Toast("收货人手机号不能为空");
            }
            if (!this.date) {
                return vant.Toast("预约兑换时间不能为空");
            }
            const phone = this.phone.replace(/\-/g, "").replace(/\s/g, "");
            if (phone.length !== 11 && phone.length !== 12) {
                return vant.Toast("请输入正确的收货人手机号");
            }
            if (!this.address1) {
                return vant.Toast("省市区不能为空");
            }
            if (!this.address2) {
                return vant.Toast("详细地址不能为空");
            }
            this.phone = phone;
            this.showDialog = true;

        },
        confirmCancel() {
            this.showDialog = false;
        },
        async confirmOk() {
            try {
                const res = await agent.post(
                    `https://portal.sqbj.com/gc/h5/card/submit`,
                    {
                        cardNo: this.cardNumber,
                        pwd: this.cardPassword,
                        consignee: this.name,
                        phone: this.phone,
                        etTime: `${this.date} 00:00:00`,
                        address: this.address1.replace(/\//g, ""),
                        detailAddress: this.address2,
                    }
                );
                if (res.code === 0) {
                    this.showSuccessPage = true;
                } else {
                    vant.Toast(res.msg || "操作失败2");
                }
            } catch (e) {
                vant.Toast(e.message || "操作失败1");
            }
        },
        close() {
            if (window && window.WeixinJSBridge) {
                window.WeixinJSBridge.call('closeWindow');
            }
        }
    },
};
</script>
<style lang="less">
.hairyCrab {
    height: 100%;

    .form {
        height: 100%;
        padding: 30px;
        font-size: 14px;
        line-height: 2em;

        .label {
            color: #999;
            font-weight: bolder;
        }

        .formItem {
            position: relative;
            margin-bottom: 15px;

            .formItemInput {
                height: 40px;
                padding: 11px 12px;
                margin-bottom: 15px;
                border: 1px solid #ddd;
                border-radius: 4px;
                line-height: 16px;

                input {
                    width: 100%;
                    height: 16px;
                    font-size: 14px;

                    &::placeholder {
                        color: silver;
                    }

                    &.special {
                        pointer-events: none;
                    }
                }
            }

            .warning {
                position: absolute;
                top: 40px;
                font-size: 13px;
                line-height: 1.5em;
                color: red;
            }
        }

        .submit {
            padding: 30px;
            display: flex;
            justify-content: center;

            .submitBtn {
                background: #4b7afb;
                padding: 6px 26px;
                color: #fff;
                border: none;
                border-radius: 4px;

                &:disabled {
                    background: silver;
                    cursor: not-allowed;
                }
            }
        }

        .tips {
            font-size: 14px;
            line-height: 1.5em;
        }
    }

    .vDistpicker {
        display: flex;
        flex-direction: column;
        position: fixed;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;

        .mask {
            background: rgba(0, 0, 0, .3);
            flex: 1;
        }

        .distpicker-address-wrapper {
            display: flex;
            flex-direction: column;
            height: 50%;

            // .address-header {

            // }

            .address-container {
                flex: 1;
                overflow: auto;
            }
        }
    }

    .successPage {
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 100%;
        padding: 20px;

        .icon {
            background-image: url(https://public.img.oss.shequbanjing.com/property/application/2fe82b0e-0418-4159-9612-02644e4f581a.png);
            background-size: 100% 100%;
            width: 50px;
            height: 50px;
            margin-top: 100px;
        }

        .text {
            margin-top: 14px;
            font-size: 15px;
            font-family: PingFang SC, PingFang SC-Regular;
            font-weight: 400;
            text-align: center;
            color: #3c3c3c;
            line-height: 23px;
            letter-spacing: 0.01px;
        }

        .btn {
            background: #29c985;
            width: 100%;
            margin-top: 80px;
            border-radius: 5px;
            font-size: 16px;
            font-weight: 400;
            text-align: center;
            color: #ffffff;
            line-height: 40px;
        }
    }
}
</style>
